html, body, #root, .app-container {
  display: flex;
  height: 100vh;
  width: 100%;
  user-select: none;
  margin: 0;
  padding: 0;
  border: 0;
  touch-action: manipulation;
  -ms-touch-action: manipulation;
}

.team-container {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.team-a {
  background-color: blue;
}

.team-b {
  background-color: red;
}

.name {
  color: white;
  background-color: transparent;
  border-color: transparent;
  font-size: 7vmin;
  height: 100%;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
  border: 0;
  max-height: 15vmin;
  font-family: georgia,serif;
}

.score {
  color: white;
  font-size: 18vmax;
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'courier new',monospace;
  font-weight: bold;
}

.controls-container {
  position: absolute;
  bottom: 20px;
  left: 20px;
  display: flex;
  gap: 10px;
}

.settings-container {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
}